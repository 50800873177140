import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connectStats } from 'react-instantsearch-dom';

const TABLET_MAX_WIDTH = 768;

const getResultPage = (url, type, changeTab, query = '') => {
  const isDesktop = window.innerWidth >= TABLET_MAX_WIDTH;

  if (isDesktop) {
    window.location = `${url}?section=${type}&q=${encodeURIComponent(query)}`;
  } else {
    changeTab(type);
  }
};

const AlgoliaHeader = connectStats(
  ({ nbHits, header, hide, resultUrl, type, changeTab, query }) => {
    if (hide) {
      return null;
    }

    return (
      <button
        type="button"
        key={`${type}_header`}
        onClick={() => getResultPage(resultUrl, type, changeTab, query)}
        className={classNames(
          'header-footer-algolia-result__row-column-label',
          {
            'header-footer-algolia-result__row-column-label--no-results':
              !nbHits,
          },
        )}
      >
        {header}
        <span className="header-footer-algolia-result__row-column-label-count">
          ({nbHits})
        </span>
      </button>
    );
  },
);

AlgoliaHeader.propTypes = {
  nbHits: PropTypes.number,
  header: PropTypes.string,
  hide: PropTypes.bool,
  searchState: PropTypes.shape({}),
};

export default AlgoliaHeader;
