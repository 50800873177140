import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Icon } from '@bwoty-web/ui-kit';
import { UI_SEARCH, UI_CROSS_CIRCLE, UI_CROSS } from '@bwoty-web/ui-kit/icons'
import keycode from 'keycode';

import debounce from 'debounce';

import './AlgoliaSearchBox.scss';

const TABLET_MAX_WIDTH = 768;
const TABLET_MAX_HEIGHT = 1024;

const AlgoliaSearchBox = ({
  onCancel,
  onClick,
  onChange,
  refine,
  currentRefinement,
  placeholder,
  cancelText,
  isOpen,
  id,
}) => {
  const textInput = useRef(null);
  const [focused, setFocused] = useState(false);

  const onKeyInput = (e) => {
    switch (keycode(e)) {
      case 'esc':
        onCancel();
        if (textInput.current) textInput.current.blur();
        break;
      case 'enter':
        e.stopPropagation();
        if (
          window.innerWidth >= TABLET_MAX_WIDTH &&
          window.innerHeight >= TABLET_MAX_HEIGHT
        ) {
          e.preventDefault();
        } else {
          if (textInput.current) textInput.current.blur();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (textInput.current) {
      textInput.current.addEventListener('keydown', onKeyInput, false);

      if (isOpen) {
        textInput.current.focus();
      }
    }
    return () => {
      if (textInput.current) {
        textInput.current.removeEventListener('keydown', onKeyInput, false);
      }
    };
  }, []);

 
  const onOpen = () => {
    if (
      window.innerWidth < TABLET_MAX_WIDTH &&
      window.innerHeight < TABLET_MAX_HEIGHT
    ) {
      onClick();
    }

    setTimeout(() => {
      if (textInput.current) textInput.current.focus();
    }, 200);
  };

  const onClear = () => {
    const value = '';
    textInput.current.value = value;
    refine(value);
  };

  const debouncedOnChange = debounce((value) => {
    refine(value);
  }, 300);

  const onChangeFunc = (e) => {
    onChange();
    debouncedOnChange(e.target.value);
  };

  return (
    <div className="header-footer-algolia-search-box">
      <label
        className={classNames('header-footer-algolia-search-box__input', {
          'header-footer-algolia-search-box__input--focused': focused,
        })}
        htmlFor="searchInput"
      >
        <button
          type="button"
          className="header-footer-algolia-search-box__input-icon"
          id="header-footer-algolia-input-icon"
          onClick={onOpen}
        >
          <Icon
            className="header-footer-algolia-search-box__input-icon-svg"
            path={UI_SEARCH}
            size="xs"
          />
        </button>
        <input
          ref={textInput}
          id={id}
          type="text"
          name="algoliaSearchInput"
          className="header-footer-algolia-search-box__input-field"
          placeholder={placeholder}
          aria-autocomplete="list"
          aria-expanded={focused}
          aria-controls="expandable"
          role="search"
          autoComplete="off"
          onChange={(e) => onChangeFunc(e)}
          onFocus={() => {
            if (currentRefinement.length > 0) {
              onClick();
            }
            setFocused(true);
          }}
          onBlur={() => setFocused(false)}
        />
        {currentRefinement && (
          <button
            className="header-footer-algolia-search-box__clear"
            type="button"
            onClick={() => onClear()}
          >
            <Icon
              className="header-footer-algolia-search-box__clear-icon"
              path={UI_CROSS_CIRCLE}
              size="xs"
              sizeMobile="xxs"
            />
          </button>
        )}
      </label>
      {isOpen && (
        <button
          className="header-footer-algolia-search-box__cancel"
          type="button"
          onClick={onCancel}
        >
          <Icon
            className="header-footer-algolia-search-box__cancel-icon"
            path={UI_CROSS}
            size="xs"
          />
          <span className="header-footer-algolia-search-box__cancel-text">
            {cancelText}
          </span>
        </button>
      )}
    </div>
  );
};

AlgoliaSearchBox.propTypes = {
  refine: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  isOpen: PropTypes.bool,
  currentRefinement: PropTypes.string,
  id: PropTypes.string.isRequired,
};

AlgoliaSearchBox.defaultProps = {
  refine: () => {},
  onChange: () => {},
  placeholder: '',
  onClick: () => {},
  onCancel: () => {},
  cancelText: '',
  isOpen: false,
  currentRefinement: '',
};

export default connectSearchBox(AlgoliaSearchBox);
