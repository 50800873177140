import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import {
  ArrowLink,
  CloudinaryPicture,
  Icon,
  LoadBlock,
} from '@bwoty-web/ui-kit';
import {
  UI_INFO,
  MEDIA_PHOTO,
} from '@bwoty-web/ui-kit/icons';
import ShowMore from './_ShowMore';
import dataLayerPush from './dataLayer';
import './AlgoliaHit.scss';

const propTypes = {
  hit: PropTypes.shape({}),
  query: PropTypes.string,
};

const defaultProps = {
  hit: {},
  query: '',
};

const goToHit = (url, type = '', query = '') => {
  const typeStr = type.charAt(0).toUpperCase() + type.slice(1);

  dataLayerPush({
    event: 'site_search_category',
    eventCategory: 'Site Search',
    eventAction: `Click ${typeStr} Link`,
    eventLabel: url,
    siteSearchQuery: query,
  });
  window.location = url;
};

export const AlgoliaHitItem = connectStateResults(({ hit, searchState }) => {
  return (
    <button
      type="button"
      className="header-footer-algolia-hit"
      onClick={() => goToHit(hit.url, hit.type, (searchState?.query || ''))}
    >
      {hit.type === 'faq' ? (
        <Icon
          className="header-footer-algolia-hit__icon"
          path={UI_INFO}
          size="s"
        />
      ) : (
        <div className="header-footer-algolia-hit__image">
          <CloudinaryPicture
            img={{
              cloudinaryUriTemplate: hit?.cloudinaryUriTemplate,
            }}
            className="header-footer-algolia-hit__image-src"
            sizes={[{
              height: 80,
              width: 80,
            }]}
            alt={hit.name}
            lazy
            errorIcon={<Icon path={MEDIA_PHOTO} size="s" />}
          />
        </div>
      )}
      <div className="header-footer-algolia-hit__content">
        <div className="header-footer-algolia-hit__content-name">{(hit.type === 'faq' || hit.type === 'umbraco') ? hit.title : hit.name}</div>
        {hit.type !== 'faq' && hit.type !== 'umbraco' && (
          <div className="header-footer-algolia-hit__content-geographical">
            {hit.area && <div className="header-footer-algolia-hit__content-geographical-item">{hit.area}</div>}
            {hit.country && <div className="header-footer-algolia-hit__content-geographical-item">{hit.country}</div>}
          </div>
        )}
      </div>
    </button>
  );
});

AlgoliaHitItem.propTypes = propTypes;
AlgoliaHitItem.defaultProps = defaultProps;

const AlgoliaHitStalled = () => {
  return (
    <div className="header-footer-algolia-hit header-footer-algolia-hit--loading">
      <div className="header-footer-algolia-hit__image">
        <LoadBlock style={{ width: '100%', height: '35px' }} />
      </div>
      <div className="header-footer-algolia-hit__content">
        <div className="header-footer-algolia-hit__content-name" style={{ marginBottom: '5px' }}>
          <LoadBlock style={{ width: '100%', height: '20px' }} />
        </div>
        <div className="header-footer-algolia-hit__content-geographical">
          <LoadBlock style={{ width: '100%', height: '10px' }} />
        </div>
      </div>
    </div>
  );
};

const AlgoliaHit = connectHits(({
  hits,
  className,
  type,
  resultLabel = '',
  tabSelected,
  hide,
  changeTab,
  texts,
  isSearchStalled,
}) => {
  if (hide) {
    return null;
  } 
  if (!hits.length && !isSearchStalled) {
    const messageSplit = resultLabel.split(/{|}/);
    const noResultText = `${messageSplit[0]} ${messageSplit[2]}`;

    return (
      <span className="header-footer-algolia-result-link__no-results">
        <div><i>{texts.notFoundLabel}</i></div>
        {texts.noResultLink[type] && (
          <div className="header-footer-algolia-result-link__no-results-link">
            <ArrowLink href={texts.noResultLink[type]}>{noResultText}</ArrowLink>
          </div>
        )}
      </span>
    );
  }

  if (isSearchStalled) {
    return (
      <div className={className}>
        <AlgoliaHitStalled />
      </div>
    );
  }

  return (
    <div className={className}>
      {hits.map(hit => <AlgoliaHitItem key={hit.objectID} hit={hit} />)}
      <ShowMore
        url={texts.resultUrl}
        type={type}
        resultLabel={resultLabel}
        hide={tabSelected}
        changeTab={changeTab}
      />
    </div>
  );
});

export default AlgoliaHit;
