import { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AlgoliaSearch from './AlgoliaSearch/AlgoliaSearch';
import './searchField.scss';

const SearchField = ({ content, algoliaConfig, siteId, imageDomain }) => {
  const {
    faqHeader,
    hotelHeader,
    notFoundLabel,
    destinationHeader,
    resultUrl,
    resultLabels,
    noResultLink,
    showMore,
    noPriceLabel,
    hotelResultLabel,
    guestRatingResultLabel,
    pageHeader,
    refineSearch,
    button,
    showAllResult,
    tabAllHeader,
    adultFriendly,
    childFriendly,
    searchPlaceholderText,
    searchCancelBtnText,
    searchButtonLabel,
  } = content;

  const [algoliaOpen, setAlgoliaOpen] = useState(false);

  useEffect(() => {
    const algoliaInputIcon = document.getElementById('header-footer-algolia-input-icon');
    if (algoliaInputIcon) {
      setTimeout(() => {
        algoliaInputIcon.click();
      }, 200);
    }
  }, []);

  const onCancel = () => {
    document?.body?.classList?.remove('no-scroll');
    setAlgoliaOpen(false);
  };

  const onInput = () => {
    if (!algoliaOpen) {
      setAlgoliaOpen(true);
    }
  };

  const clickAlgoliaSearch = (isOpen) => {
    setAlgoliaOpen(!!isOpen);
    if (isOpen) {
      setTimeout(() => {
        document?.body?.classList?.add('no-scroll');
      }, 0);
    } else {
      document?.body?.classList?.remove('no-scroll');
    }
  };

  const algoliaClassNames = classNames('header-search-field-algolia', {
    'header-search-field-algolia--open': algoliaOpen,
  });

  const fieldAlgoliaClassNames = classNames({
    'header-search-field-algolia__container-search-bar': !algoliaConfig.withoutPreInputBox,
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={algoliaClassNames} onClick={onCancel}>
      <div className="header-search-field-algolia__container">
        <AlgoliaSearch
          config={algoliaConfig}
          siteId={siteId}
          imageDomain={imageDomain}
          texts={{
            cancelLabel: searchCancelBtnText,
            placeholder: searchPlaceholderText,
            faqHeader,
            hotelHeader,
            notFoundLabel,
            destinationHeader,
            resultLabels,
            resultUrl,
            noResultLink,
            showMore,
            noPriceLabel,
            hotelResultLabel,
            guestRatingResultLabel,
            pageHeader,
            refineSearch,
            button,
            showAllResult,
            tabAllHeader,
            adultFriendly,
            childFriendly,
            searchButtonLabel,
          }}
          onChange={onInput}
          open={algoliaOpen}
          className={fieldAlgoliaClassNames}
          onClick={clickAlgoliaSearch}
        />
      </div>
    </div>
  );
};

SearchField.propTypes = {
  siteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  content: PropTypes.shape({
    searchPlaceholderText: PropTypes.string.isRequired,
    searchCancelBtnText: PropTypes.string.isRequired,
    faqHeader: PropTypes.string.isRequired,
    hotelHeader: PropTypes.string.isRequired,
    notFoundLabel: PropTypes.string.isRequired,
    destinationHeader: PropTypes.string.isRequired,
    resultUrl: PropTypes.string.isRequired,
    resultLabels: PropTypes.shape({}),
    noResultLink: PropTypes.shape({}),
    showMore: PropTypes.string.isRequired,
    noPriceLabel: PropTypes.string.isRequired,
    hotelResultLabel: PropTypes.string.isRequired,
    guestRatingResultLabel: PropTypes.string.isRequired,
    pageHeader: PropTypes.string.isRequired,
    refineSearch: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    showAllResult: PropTypes.string.isRequired,
    tabAllHeader: PropTypes.string.isRequired,
    adultFriendly: PropTypes.string.isRequired,
    childFriendly: PropTypes.string.isRequired,
    searchButtonLabel: PropTypes.string,
  }).isRequired,
  imageDomain: PropTypes.string.isRequired,
  algoliaConfig: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    appId: PropTypes.string.isRequired,
    faqIndex: PropTypes.string.isRequired,
    hotelIndex: PropTypes.string.isRequired,
    destinationIndex: PropTypes.string.isRequired,
    withoutPreInputBox: PropTypes.bool,
  }),
};

SearchField.defaultProps = {
  algoliaConfig: {},
};

export default SearchField;
