import { render } from 'react-dom';
import { Globals } from '@bwoty-web/ui-kit';
import SearchField from '../shared/header/components/SearchField';

const initialStateHeader = window.__INITIAL_STATE_HEADER__;
// eslint-disable-next-line camelcase, no-undef
__webpack_public_path__ = `${initialStateHeader?.baseUrl}/static/`;

delete window.__INITIAL_STATE_HEADER__;
const algoliaRoot = document.getElementById('algolia-search');

if (algoliaRoot) {
  render(
    <Globals siteId={initialStateHeader.siteId} >
      <SearchField {...initialStateHeader} />
    </Globals>,
    algoliaRoot,
  );
}
